<script setup lang="ts">
import { useKeenSlider } from 'keen-slider/vue'
import 'keen-slider/keen-slider.min.css'

const props = defineProps<{
  data: {
    id: string
    translations: DirectusTranslation<{
      section_title?: string
      title?: string
      button_text?: string
      button_link?: string
    }>
    items: {
      block_quote_carousel_item_id: {
        id: number
        image: string
        link: string
        translations: DirectusTranslation<{
          title: string
          text: string
          person_subtitle: string
          person_title: string
        }>
      }
    }[]
  }
}>()

const [container, slider] = useKeenSlider({
  loop: true,
  renderMode: 'performance',
  drag: true,
  slides: {
    perView: 1,
    spacing: 30,
  },
  breakpoints: {
    '(min-width: 1024px)': {
      slides: {
        perView: 2.2,
        spacing: 30,
      },
    },
  },
})

function nextSlide() {
  slider?.value?.next()
}
function prevSlide() {
  slider?.value?.prev()
}

const slides = computed(() => {
  return props.data.items.map((item) => {
    return {
      id: item.block_quote_carousel_item_id.id,
      image: item.block_quote_carousel_item_id.image,
      link: item.block_quote_carousel_item_id.link,
      title: dt(item.block_quote_carousel_item_id.translations)?.title,
      text: dt(item.block_quote_carousel_item_id.translations)?.text,
      person_subtitle: dt(item.block_quote_carousel_item_id.translations)?.person_subtitle,
      person_title: dt(item.block_quote_carousel_item_id.translations)?.person_title,
    }
  })
})
</script>

<template>
  <BlockBase class="bg-starline-secondary py-4rem lg:py-20rem">
    <Container class="w-full gap-10rem lg:flex-row">
      <div class="mb-2rem w-full flex flex-wrap items-end justify-between gap-4rem">
        <div>
          <TextWithAfterLine v-if="dt(props.data.translations)?.section_title" :text="dt(props.data.translations)?.section_title ?? ''" color="white" />
          <h1
            class="max-w-85rem text-balance text-neutral-white textH1"
          >
            {{ dt(props.data.translations)?.title }}
          </h1>
        </div>
        <div class="mb-2rem">
          <BtnSecondary
            v-if="dt(props.data.translations)?.button_link && dt(props.data.translations)?.button_text"
            :text="dt(props.data.translations)?.button_text"
            :link="dt(props.data.translations)?.button_link"
          />
        </div>
      </div>
      <!-- SLIDER -->
      <div ref="container" class="keen-slider">
        <!-- Slide -->
        <template v-for="item in slides" :key="item.id">
          <div class="keen-slider__slide flex">
            <NuxtLinkLocale :to="item.link" class="group block w-full pb-14rem lg:pb-0">
              <div class="border border-neutral-darkGray px-3rem pb-7rem pt-5rem transition group-hover:border-starline-primary group-hover:shadow-starline">
                <h2 class="mb-2rem text-2.8rem font-title">
                  {{ item.title }}
                </h2>
                <p class="whitespace-pre-line text-1.6rem">
                  {{ item.text }}
                </p>
              </div>
              <div class="ml-3rem flex items-end gap-2rem -mt-2rem">
                <NuxtImg loading="lazy" provider="directus" :src="item.image" alt="item.title" height="150" width="150" class="size-15rem object-cover" />
                <div>
                  <span class="text-1.6rem text-neutral-gray font-title">{{ item.person_subtitle }}</span><br>
                  <span class="text-1.3rem tracking-.4rem uppercase">{{ item.person_title }}</span>
                </div>
              </div>
            </NuxtLinkLocale>
          </div>
        </template>

        <!-- Controls -->
        <div class="absolute bottom-2rem right-2rem h-10rem w-15rem flex items-center justify-center border border-neutral-darkGray bg-starline-secondary text-neutral-white xl:bottom-3rem">
          <button class="h-10rem w-7.5rem flex items-center justify-center hover:bg-neutral-black/20" @click="prevSlide">
            <IconSmallArrow class="h-2rem w-2rem fill-current" />
          </button>
          <button class="h-10rem w-7.5rem flex items-center justify-center hover:bg-neutral-black/20" @click="nextSlide">
            <IconSmallArrow class="h-2rem w-2rem rotate-180 fill-current" />
          </button>
        </div>
      </div>
    </Container>
  </BlockBase>
</template>

<style scoped lang="scss">
.keen-slider {
  @apply right-3rem p-3rem;

  @media only screen and (max-width: 1280px) {
    @apply right-0 p-0rem;
  }

  &__slide {
    overflow: visible !important;
    @apply relative;

    &:hover {
      .slideout {
        @apply scale-x-100;
      }
    }
  }

  .slideout {
    @apply scale-x-0 transition origin-left duration-500 ease-in-out;
  }
}
</style>
